/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function Footer() {
  return (
    <div className="footer">
      <div className="panel-wrapper cl-bg">
        <Container>
          <div className="text-center">
            <h5 className="footer-head">CONTACT INFORMATION</h5>
            <p>Phone / Text : <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a></p>
            <p>Email : <u><a href="mailto:info@parknation.org" runtime_url="mailto:info@parknation.org">info@parknation.org</a></u></p>
            <p>Address: 350 Berlin Cross Keys Rd, Sicklerville, NJ 08081</p>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper dark-bg">
        <Container>
          <div className="text-center">
            <p className="terms-conditions">Content, including images, displayed on this website is protected by copyright laws. Downloading, republication, retransmission or reproduction of content on this website is strictly prohibited. <a href="/terms" rel="nofollow" target="_self"><u>Terms of Use</u></a> | <a href="/privacy-policy" rel="nofollow" target="_self"><u>Privacy Policy</u></a></p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
