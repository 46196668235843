import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function ContactUs() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center"><h1 className="head1">TRUCKING AND COMMERCIAL PARKING LOT IN SICKLERVILLE, NEW JERSEY</h1></div>
          <p>When you need a place to <b>park your semi-truck,</b> your vehicle fleet, or your heavy equipment, the best facility to call is <b>ParkNation</b>. With dependable security features, assigned parking, and great customer service, we create convenient experiences for every customer.</p>
          <br/>
          <p>Call us today and we will save a parking space for you.</p>
          <div className="row">
            <div className="col-md-6 text-center" style={{marginTop: '15px'}}>
              <p><b>ParkNation</b></p>
              <p>350 Berlin - Cross Keys Rd,<br/>Sicklerville,NJ 08081</p>
	  <p>Development Center: 281 Summerhill rd, east Brunswick, nj 08816 </p>
              <p><b>Phone / Text :</b> <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a></p>
              <p><b>Email :</b> <u><a href="mailto:info@parknation.org" runtime_url="mailto:info@parknation.org">info@parknation.org</a></u></p>
              <img className="contact-img" src={require('assets/img/contactus.jpg')} alt="parknation" />
            </div>
            <div className="col-md-6">
              <div className="cl-bg contact">
                <h4 className="head text-center"><b>Contact Us</b></h4>
                <form>
                  <div class="form-group">
                    <input type="text" class="form-control cl-brd" placeholder="Name" />
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control cl-brd"  placeholder="Phone" />
                  </div>
                  <div class="form-group">
                    <input type="email" class="form-control cl-brd" placeholder="Email" />
                  </div>
                  <div class="form-group">
                    <textarea type="email" class="form-control cl-brd" />
                  </div>
                  <button type="submit" class="btn btn-primary wht-btn">Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <a href="https://www.google.com/maps/place/ParkNation+LLC/@39.760198,-74.971551,15z/data=!4m5!3m4!1s0x0:0x68512f2b05e700de!8m2!3d39.760198!4d-74.971551" file="false" rel="noopener noreferrer" target="_blank"><img src={require('assets/img/GoogleMap-2880w.webp')} alt="googleMaps" /></a>
    </div>
  );
}

export default ContactUs;
