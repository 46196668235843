import React from "react";
import parse from "html-react-parser";

// reactstrap components
import {
  Container,
} from "reactstrap";

function TermsOfUse() {
  var htmlString = `
     <html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=windows-1252">
    <style>
  [data-custom-class='body'], [data-custom-class='body'] * {
          background: transparent !important;
        }
[data-custom-class='title'], [data-custom-class='title'] * {
          font-family: Arial !important;
font-size: 26px !important;
color: #000000 !important;
        }
[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
          font-family: Arial !important;
color: #595959 !important;
font-size: 14px !important;
        }
[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
          font-family: Arial !important;
font-size: 19px !important;
color: #000000 !important;
        }
[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
          font-family: Arial !important;
font-size: 17px !important;
color: #000000 !important;
        }
[data-custom-class='body_text'], [data-custom-class='body_text'] * {
          color: #595959 !important;
font-size: 14px !important;
font-family: Arial !important;
        }
[data-custom-class='link'], [data-custom-class='link'] * {
          color: #3030F1 !important;
font-size: 14px !important;
font-family: Arial !important;
word-break: break-word !important;
        }
</style> </head>
  <body>
    <div data-custom-class="body">
      <div><strong><span style="font-size: 26px;"><span data-custom-class="title">TERMS OF USE
              </span></span></strong></div>
      <div><br>
      </div>
      <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
                data-custom-class="subtitle">Last updated <bdt class="question">July
                  03, 2022</bdt></span></span></strong></span></div>
      <div><br>
      </div>
      <div><br>
      </div>
      <div><br>
      </div>
	<h5>Terms</h5>
	<br>
By accessing and using this website, web page, client portal, or mobile application, including but not limited to any content, functionality and services offered on or through this website, web page, client portal, or mobile application, or our e-mails, texts, posts and other electronic messages (collectively, our “Site”), you are agreeing to be bound by these Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this Site.

Our Site and all of the contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by us, our licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws, as applicable. We reserve the right to withdraw or amend our Site, and any service or material we provide on our Site, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of our Site is unavailable at any time or for any period.
<br><br>
<h5>Use License</h5>
Your permission to use and access this Site is the grant of a limited license, not a transfer of title, and your limited license to use or access our Site shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time for any reason or no reason.
<ul>
<li>When using or accessing our Site, you may not:</li>
  <ol class="mb-2">
    <li>modify or copy any material or Services;</li>
    <li>use the material or Services for any commercial purpose, or for any public display (commercial or non-commercial);</li>
    <li>attempt to decompile or reverse engineer any software contained on the Site or that supports the Site or any Services;</li>
    <li>engage in any data mining, data harvesting, data extracting or any other similar activity in relation to this Site, or while using this Site;</li>
    <li>remove any copyright or other proprietary notations from the material; or</li>
    <li>transfer the materials to another person or entity or “mirror” the material on any other server.</li>
  </ol>
 
<li>Posting Content. In these Terms and Conditions of Use, your “Content” shall mean any audio, video, text, images or other material, including comments or feedback, you choose to post on or submit to us via this Website. With respect to your Content, by posting it in a way that is visible to the public, you grant us a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it on our Site and in any and all media. Your Content must be your own and must not be infringing on any third party’s rights or violate any of the restrictions in the subsection below. We reserve the right to remove any of your Content from our Site at any time, and for any reason, without notice.</li>

<li>Without limiting the foregoing, you agree not to use, or permit to be used, any messaging capabilities or any Services on our Site that permit text entry or the uploading or posting of audio, video or images to post, transmit or disseminate any:
  <ol class="mb-2">
    <li>unsolicited material to persons or entities that have not agreed to receive such material or to whom you do not otherwise have a legal right to send such material;</li>
    <li>material that infringes or violates any third party’s intellectual property rights, rights of publicity, privacy, or confidentiality, or the rights or legal obligations of any wireless service provider or any of its customers or subscribers attempt to decompile or reverse engineer any software contained on the Site or that supports the Site or any Services;</li>
    <li>material or data that is illegal, or material or data that is harassing, coercive, libelous, defamatory, abusive, threatening, obscene, or otherwise objectionable, materials that are harmful to minors or excessive in quantity, or materials the transmission of which could diminish or harm the reputation of us and/or our third- party service providers; material or data that is related to illegal drugs (e.g., marijuana, cocaine) or to pharmaceuticals, material that contains any viruses, Trojan horses, worms, time bombs, cancelbots, or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data, or personal information or contains any signal or impulse that could cause electrical, magnetic, optical, or other technical harm to our equipment or facilities and/or those of any third party; or material or information that is false or misleading, or likely to mislead or deceive. </li>
  </ol>
</li>

<li>Your permission to use and access this Site is the grant of a limited license, not a transfer of title, and your limited license to use or access our Site shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time for any reason or no reason.</li>
</ul>

		<h5>Disclaimer</h5>
You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or our Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF OUR SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH OUR SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO OR FROM IT. THE MATERIALS ON THE SITE ARE PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS. WE MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, AND HEREBY DISCLAIMS AND NEGATES ALL OTHER WARRANTIES, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR OTHER VIOLATION OF RIGHTS. FURTHER, WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS CONCERNING THE ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE MATERIALS ON SITE OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY SITE LINKED TO THE SITE.

<h5>Limitation of Liability</h5>
IN NO EVENT WILL WE, OUR AFFILIATES OR OUR OR THEIR LICENSORS, SERVICE PROVIDERS, AGENTS, EMPLOYEES, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OUR SITE, ANY WEBSITES LINKED TO OR FROM IT, ANY CONTENT ON OUR SITE OR ON SUCH OTHER WEBSITES, OR ANY SERVICES OR ITEMS OBTAINED THROUGH OUR SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.<br>

		<h5>Indemnification</h5>

You agree to defend, indemnify and hold harmless us, our affiliates, licensors and service providers, and our and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms and Conditions of Use and your use of our Site, including, but not limited to, any use of our Site’s content, services and products other than as expressly authorized in these Terms and Conditions of Use or your use of any information obtained from our Site.

<h5>Revisions and Errata</h5>
The materials appearing on our Site could include technical, typographical, or photographic errors. We do not warrant that any of the materials on the Site are accurate, complete, or current. We may make changes to the materials contained on the Site at any time without notice. We do not, however, make any commitment to update the materials.

<h5>Links</h5>
We have not reviewed all of the third-party websites linked to by the Site and are not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by us of the third-party website. Navigation to and use of any such linked website is at the user's own risk.

<h5>Site Terms of Use Modifications</h5>
We may revise these Terms and Conditions of Use for the Site at any time without notice. By accessing or using the Site, you are agreeing to be bound by the then current version of these Terms and Conditions of Use.

<h5>Governing Law</h5>
Any claim relating to the Site shall be governed by the laws of the State in which we are headquartered without regard to its conflict of law provisions.

<h5>Entire Agreement</h5>
These Terms and Conditions of Use, our Privacy Policy and any other document we deem relevant constitute the sole and entire agreement between you and us with respect to our Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to our Site
</div>
</div>
<br><br>
<div>		SMS/MMS MOBILE MESSAGING MARKETING PROGRAM
<br>
We respect your privacy. We will only use the information you provide through the Program to transmit your mobile messages and respond to you, if necessary. This includes but is not limited to, sharing information with platform providers, phone companies, and other vendors who assist us in the delivery of mobile messages.  WE DO NOT SELL, RENT, LOAN, TRADE, LEASE, OR OTHERWISE TRANSFER FOR PROFIT ANY PHONE NUMBERS OR CUSTOMER INFORMATION COLLECTED THROUGH THE PROGRAM TO ANY THIRD PARTY. Nonetheless, We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, or governmental request, to avoid liability, or to protect Our rights or property. When you complete forms online or otherwise provide Us information in connection with the Program, you agree to provide accurate, complete, and true information. You agree not to use a false or misleading name or a name that you are not authorized to use. If, in Our sole discretion, We believe that any such information is untrue, inaccurate, or incomplete, or you have opted into the Program for an ulterior purpose, We may refuse you access to the Program and pursue any appropriate legal remedies.

California Civil Code Section 1798.83 permits Users of the Program who are California residents to request certain information regarding our disclosure of the information you provide through the Program to third parties for their direct marketing purposes.  To make such a request, please contact us at the following address:

	<br>
                                                                  <br>
                                                                  Park Nation,
                                                                  LLC<br>
                                                                  <br>
                                                                  350
                                                                  Berlin-Cross
                                                                  Keys Rd<br>
                                                                  Sicklerville,
                                                                  NJ 08081<br>
info@parknation.org<br>
                                                                  <br>

Parknation , also known as Parknation LLC offers the option to our customers to engage in conversations with drivers to provide fast solutions. Message frequency varies. Message and data rates may apply. Text HELP to (+1) 2017860983 for help. Reply STOP to cancel. Carriers are not liable for any delays or undelivered messages.
		<br><br>
This Privacy Policy is strictly limited to the Program and does not affect any other privacy policy(ies) that may govern the relationship between you and Us in other contexts.</div>
  </body>
</html>

	`;
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center">



            {parse(htmlString)}

        </div>
	  </Container>
      </div>
    </div>
  );
}

export default TermsOfUse;
