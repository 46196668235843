import React from "react";
import ReactDOM from "react-dom";
// styles
import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css?v=1.2.0";
// import NucleoIcons from "views/NucleoIcons.js";
import App from './app';

// others

ReactDOM.render(<App />, document.getElementById("root"));
