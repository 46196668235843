import React, { useState, useEffect } from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import { NavLink } from 'react-router-dom';
import logo from "assets/img/logo.png";

function IndexNavbar() {
  // const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [miniNav, setNavarbarHeight] = useState(false);
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const menuClose = () => {
    setNavbarCollapse(false);
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        window.innerWidth > 768 && document.documentElement.scrollTop > 350
      ) {
        setNavarbarHeight(true);
      }
      else if (
        window.innerWidth > 768 && document.documentElement.scrollTop < 350
      ) {
        setNavarbarHeight(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top")}>
      <Container>
        <div className="row main-head">
          <div className="col-5 col-md-4 navbar-translate">
            <NavbarBrand
              href="/"
              target="_blank"
              className="logo text-center"
            >
              <img src={logo} alt="logo" className={`${miniNav && 'mini-nav'}`} />
            </NavbarBrand>
            <div className="text-center">
              <span className={`social-icons fb ${miniNav && 'mini-nav'}`}><a href="https://facebook.com/ParkNation-Parking-Services-for-Truck-RV-Dealers-and-Equipment-324586204916292" target="_blank" rel="noopener noreferrer" ><i className="fa fa-facebook" /></a></span>
              <span className={`social-icons ln ${miniNav && 'mini-nav'}`}><a href="https://linkedin.com/in/brian-walsh-59b076184" target="_blank" rel="noopener noreferrer" ><i className="fa fa-linkedin" /></a></span>
              <span className={`social-icons yt ${miniNav && 'mini-nav'}`}><a href="https://www.youtube.com/watch?v=jdJBfyyHwe0&feature=youtu.be" target="_blank" rel="noopener noreferrer" ><i className="fa fa-youtube-play" /></a></span>
            </div>
          </div>
          <div className="col-7 col-md-3 text-center menu-head">
            <h2 className={`m-head vt-justify ${miniNav && 'mini-nav'}`}>ParkNation</h2>
            <div className="d-block d-sm-block d-md-none" style={{position: 'relative', top: '2%', lineHeight: '1.3', textAlign: 'right'}}>
              <span className="contact-text">Call / Text Us Today!</span>
              <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133" className="bld" style={{whiteSpace: 'nowrap'}}> (856) 210-2133</a>
            </div>
          </div>
          <div className="col-md-5 d-none d-sm-none d-md-block">
            <div className={`vt-justify head-contact text-right ${miniNav && 'mini-nav'}`}>
              <div>
                <span className="contact-text">Call / Text Us Today!</span>
                <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133" className="bld"> (856) 210-2133</a>
              </div>
              <br/>
              {!miniNav && (
                <div className="innerYoutubeExt">
                  <iframe src="https://www.youtube.com/embed/jdJBfyyHwe0" title="youtube" frameBorder="0" allowFullScreen=""  style={{width: '100%'}}></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Navbar expand="lg" style={{boxShadow: 'none', padding: '0', width: '100%'}}>
      <button
        aria-expanded={navbarCollapse}
        className={classnames("navbar-toggler navbar-toggler", {
          toggled: navbarCollapse,
        })}
        onClick={toggleNavbarCollapse}
      >
        <span className="navbar-toggler-bar bar1" />
        <span className="navbar-toggler-bar bar2" />
        <span className="navbar-toggler-bar bar3" />
      </button>
      <Collapse navbar isOpen={navbarCollapse} className="menu">
        <Nav navbar>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/" className="nav-link" activeClassName="active" onClick={menuClose} >
              HOME
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/semi-truck-lot" className="nav-link" activeClassName="active" onClick={menuClose} >
              SEMI-TRUCK LOT
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/parking-for-fleets" className="nav-link" activeClassName="active" onClick={menuClose} >
              PARKING FOR FLEETS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/rv-parking" className="nav-link" activeClassName="active" onClick={menuClose} >
              RV PARKING
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/park-your-equipment" className="nav-link" activeClassName="active" onClick={menuClose} >
              PARK YOUR EQUIPMENT
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/dealerships" className="nav-link" activeClassName="active" onClick={menuClose} >
              DEALERSHIPS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact data-placement="bottom" to="/contact-us" className="nav-link" activeClassName="active" onClick={menuClose} >
              CONTACT US
            </NavLink>
          </NavItem>
	  <NavItem>
          <NavLink exact data-placement="bottom" to="" className="nav-link" activeClassName="active" onClick={()=> window.open("https://besttruckparking.com/login?siteId=5", "_blank")} >
              LOGIN
            </NavLink>
         </NavItem>
	<NavItem>
          <NavLink exact data-placement="bottom" to="/privacy-policy" className="nav-link" activeClassName="active" onClick={menuClose} >
              PRIVACY POLICY
            </NavLink>
         </NavItem>

        </Nav>
      </Collapse>
      </Navbar>
    </Navbar>
  );
}

export default IndexNavbar;
