import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
// reactstrap components
import {
  Modal,
} from "reactstrap";


function FixedIcons() {
  const [modal, setModal] = useState(false);
  const [menuModal, setModalHeader] = useState(true);
  const [modalContent, setModalContent] = useState();
  const { register, handleSubmit, errors } = useForm();
  const errorMsg = "Please fill out this field";
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  // const getLogin = () => {
  //   return (
  //     <div className="text-center">
  //       <h4><b>Welcome back!</b></h4>
  //       <p>Log in to access your account</p>
  //       <br/>
  //       <div className="row">
  //         <form className="offset-md-3 col-md-6">
  //           <div class="form-group">
  //             <input type="email" class="form-control" placeholder="Email" />
  //           </div>
  //           <button type="submit" className="btn-full btn">GET LOGIN CODE</button>
  //         </form>
  //       </div>
  //     </div>
  //   )
  // }
  const GetBookingSlot = () => {
    return (
      <div className="" style={{width: '80%'}}>
        <p><b>Please Select One Of The Following Reservation Time Slots</b></p>
        <Button className="toggleButton form-control" onClick={toggle} style={{ marginBottom: '1rem' }}><span className="pull-left">Parking Reservation</span><span className="pull-right">-</span></Button>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              Reservation slots
            </CardBody>
          </Card>
        </Collapse>
      </div>
    )
  }

  const GetMaps = () => {
    return (
      <div className="text-center maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6134.170171327542!2d-74.971551!3d39.760198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6d333b3c3ca8f%3A0x492d9ef775085d5a!2s350%20Berlin%20-%20Cross%20Keys%20Rd%2C%20Sicklerville%2C%20NJ%2008081!5e0!3m2!1sen!2sus!4v1594815090837!5m2!1sen!2sus" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0" title="parknation"></iframe>
      </div>
    )
  }

  const onSubmit = (data) => {
    window.location.href='mailto:info@parknation.org?subject='+data.subject+'&body=Message: '+data.message+'%0D%0A%0D%0AVehicle Type: '+data.vehicleType+'%0D%0A%0D%0AFirst Name: '+data.firstName+'%0D%0A%0D%0ALast Name: '+data.lastName+'%0D%0A%0D%0AEmail: '+data.email+'%0D%0A%0D%0APhone: '+data.phone+'%0D%0A%0D%0AAddress: '+data.address+'%0D%0A%0D%0APromotions: '+data.promotions;
  }


  const GetEmailContent = () => {
    return (
      <div>
        <form className="offset-md-3 col-md-6">
          <div className="form-group">
            <input type="text" name="subject" id="subject" aria-invalid={errors.subject ? "true" : "false"} defaultValue="Contact request" className="form-control" placeholder="Subject" ref={register({ required: true })} />
            {errors.subject && <p className="error">{errorMsg}</p>}
          </div>
          <div className="form-group">
            <textarea type="text" name="message" id="message" aria-invalid={errors.message ? "true" : "false"} className="form-control" placeholder="Message" ref={register({ required: true })} />
            {errors.message && <p className="error">{errorMsg}</p>}
          </div>
          <p>Client Info</p>
          <div className="form-group">
            <input type="text" name="vehicleType" defaultValue="" className="form-control" placeholder="Vehicle Type" ref={register} />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" name="firstName" defaultValue="" className="form-control" placeholder="First Name" ref={register({ required: true })} />{errors.firstName && <p className="error">{errorMsg}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" name="lastName" defaultValue="" className="form-control" placeholder="Last Name" ref={register({ required: true })} />{errors.lastName && <p className="error">{errorMsg}</p>}
              </div>
            </div>
          </div>
          <div className="form-group">
            <input type="email" name="email" className="form-control" placeholder="Email" ref={register({ required: true })} />
            {errors.email && <p className="error">{errorMsg}</p>}
          </div>
          <div className="form-group">
            <input type="text" name="phone" className="form-control" placeholder="Phone" ref={register({ required: true })} />
            {errors.phone && <p className="error">{errorMsg}</p>}
          </div>
          <div className="form-group">
            <input type="text" name="address" className="form-control" placeholder="Address" ref={register({ required: true })} />
            {errors.address && <p className="error">{errorMsg}</p>}
          </div>
          <div className="form-group">
            <input type="checkbox" name="promotions" ref={register} />
            <label htmlFor="promotions" style={{display: 'inline'}}> <span className="text-muted">&nbsp;&nbsp;I'd like to receive promotions and special offers</span></label><br/>
          </div>
          <p className="small"><span className="text-muted">Go here for privacy policy:</span><a href="https://www.thryv.com/client-privacy-policy/">https://www.thryv.com/client-privacy-policy/</a></p>
          <button type="submit" className="btn-full btn btn-primary form-control" onClick={handleSubmit(onSubmit)}>Submit</button>
        </form>
      </div>
    )
  }

  const toggleModal = (type) => {
    if(type === '1'){
      setModalHeader(true);
    } else{
      setModalHeader(false);
    }
    setModalContent(type);
    setModal(!modal);
  };
  return (
    <>
    <div className="fixed-icon-list">
      {/*<div className="fixed-icon"><span className="animated-icon" style={{ backgroundColor: 'rgba(111,104,100,0.95)' }} onClick={() => {toggleModal('1')}}><i className="fa fa-user" /></span><span className="animated-text">My Account</span></div>
      <div className="fixed-icon"><span className="animated-icon" onClick={() => {toggleModal('2')}}><i className="fa fa-calendar" /></span><span className="animated-text">Arrival Date and Time</span></div>
      <div className="fixed-icon"><span className="animated-icon" onClick={() => {toggleModal('3')}}><i className="fa fa-credit-card" /></span><span className="animated-text">Make a Payment</span></div>*/}
      <div className="fixed-icon"><span className="animated-icon" onClick={() => {toggleModal('4')}}><i className="fa fa-map-marker" /></span><span className="animated-text">Get Directions</span></div>
      {/*<div className="fixed-icon"><span className="animated-icon" onClick={() => {toggleModal('5')}}><i className="fa fa-tablet" /></span><span className="animated-text">Call Me Back</span></div>*/}
      <div className="fixed-icon"><span className="animated-icon" onClick={() => {toggleModal('6')}}><i className="fa fa-laptop" /></span><span className="animated-text">Contact Us</span></div>
    </div>
    <Modal isOpen={modal} toggle={toggleModal}>
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <h5 className="modal-title text-center" id="exampleModalLabel">
          {menuModal ? (
            <div style={{padding: '20px 0'}}>
              <img className="modal-logo" src={require("assets/img/logo-mini.png")} alt="parknation" />
              <p><b>PARKNATION LLC</b></p>
              <p style={{fontSize: '13px'}}>ParkNation - Parking for Semi, Large Trucks, RV's and Fleets</p>
            </div>
          ) : (
            <div className="row text-left m-0">
              <div className="col" style={{maxWidth: '60px', padding: '0'}}><img className="modal-logo" src={require("assets/img/logo-mini.png")} alt="parknation" /></div>
              <div className="col" style={{top: '22px'}}>
                <p><b>PARKNATION LLC</b></p>
                <p style={{fontSize: '13px'}}>ParkNation - Parking for Semi, Large Trucks, RV's and Fleets</p>
              </div>
            </div>
          )}
        </h5>
      </div>
      <div className="modal-body">
        {modalContent === '4' && (
          <GetMaps />
        )}
        {modalContent === '5' && (
          <GetBookingSlot/>
        )}
        {modalContent === '6' && (
          <GetEmailContent/>
        )}
      </div>
    </Modal>
    </>
  );
}

export default FixedIcons;
