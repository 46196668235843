import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import BackgroundSlider from "lib/BackgroundSlider.js";
import image1 from "assets/img/carousel1.webp";
import image2 from "assets/img/carousel2.webp";
import image3 from "assets/img/carousel3.webp";
import image4 from "assets/img/carousel4.webp";
import image5 from "assets/img/carousel5.webp";

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const containerStyle = {
    width: "100%",
    maxWidth: "640px", // Limits the max width on larger screens
    height: "auto",
    marginInline: "auto", // Center the container
    position: "relative",
  };

  const videoStyle = {
    width: "100%",
    height: "auto", // Keep video aspect ratio
  };

  const overlayStyle = {
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    pointerEvents: "none",
    textAlign: "center",
    padding: "30px", // Add padding for better spacing on mobile
  };

  const textStyle = {
    color: "white",
    margin: "0",
    fontSize: "2.5rem", // Default h1 size for large screens
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    fontWeight: 600,
    lineHeight: 1.35,
  };

  const buttonStyle = {
    display: "inline-block",
    marginTop: "15px",
    padding: "12px 30px",
    backgroundColor: "#007BFF",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "30px",
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    pointerEvents: "auto",
  };
  return (
    <>
      <div className="container nav-height" style={{ position: "relative" }}>
        {/* Video element */}
        <video autoPlay muted loop playsInline style={videoStyle}>
          <source
            src="https://parknation.io/videos/park-nation.mp4"
            type="video/mp4"
            style={videoStyle}
          />
        </video>

        <div style={overlayStyle}>
          <h2 style={textStyle} className="video-overlay-title">
            CALL US NOW FOR SECURE AND CONVENIENT TRUCK PARKING
          </h2>
          <a
            href="http://besttruckparking.com/onboarding?siteId=5&type=r"
            target="_blank"
            rel="noopener noreferrer"
            style={buttonStyle}
            className="reserve-button"
          >
            <span style={{ color: "#FFF" }}>Reserve Your Space</span>
          </a>
        </div>
      </div>

      <div className="panel-wrapper">
        <Container>
          <div className="text-center">
            <h1 className="head1">TRUCK PARKING LOT</h1>
            <p>
              Offering easy-access <b>truck parking</b> in{" "}
              <b>Sicklerville, New Jersey, ParkNation</b> is where you want to
              go for convenient commercial parking services. We are conveniently
              located to <b>Philadelphia</b> and <b>Camden</b> ports and as well
              as shipping hubs in <b>Vineland</b> and{" "}
              <b>Swedesboro, New Jersey.</b> We serve truck drivers and provide
              parking space for heavy items to give customers a safe and secure
              place to leave vehicles and other large equipment. Call / Text us
              at{" "}
              <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133">
                <b style={{ textDecorationLine: "underline" }}>
                  (856) 210-2133
                </b>
              </a>{" "}
              to secure your space. We can offer parking for one truck or an
              entire fleet. Plenty of parking is available to suit a variety of
              needs.
            </p>
            <div className="innerYoutubeEx lg-youtube">
              <iframe
                src="https://www.youtube.com/embed/jdJBfyyHwe0"
                frameBorder="0"
                title="youtube"
                allowFullScreen=""
              ></iframe>
            </div>
            <div className="text-left">
              <p>Features Include:</p>
              <ul className="innerList ul-4">
                <li>Fencing and Security Cameras</li>
                <li>Completely Paved Parking</li>
                <li>24-Hour Access</li>
                <li>Monthly Rates</li>
                <li>Electric Outlets</li>
                <li>Mobile Offices</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper cl-bg services">
        <Container>
          <div className="row text-center">
            <div className="col order-5 order-sm-1">
              <NavLink exact to="/semi-truck-lot">
                <img
                  src={require("assets/img/service1.jpg")}
                  className="cirlce"
                  alt="Semi-Truck Parking"
                />
              </NavLink>
              <p>
                <NavLink exact to="/semi-truck-lot" className="nav-link">
                  Semi-Truck Parking
                </NavLink>
              </p>
            </div>
            <div className="col order-2 order-sm-2">
              <NavLink exact to="/parking-for-fleets">
                <img
                  src={require("assets/img/service2.jpg")}
                  className="cirlce"
                  alt="Fleet Parking"
                />
              </NavLink>
              <p>
                <NavLink exact to="/parking-for-fleets" className="nav-link">
                  Fleet Parking
                </NavLink>
              </p>
            </div>
            <div className="col order-4 order-sm-3">
              <NavLink exact to="/park-your-equipment">
                <img
                  src={require("assets/img/service3.jpg")}
                  className="cirlce"
                  alt="Equipment Parking"
                />
              </NavLink>
              <p>
                <NavLink exact to="/park-your-equipment" className="nav-link">
                  Equipment Parking
                </NavLink>
              </p>
            </div>
            <div className="col order-1 order-sm-4">
              <NavLink exact to="/dealerships">
                <img
                  src={require("assets/img/service4.jpg")}
                  className="circle"
                  alt="Dealerships"
                />
              </NavLink>
              <p>
                <NavLink exact to="/dealerships" className="nav-link">
                  Dealerships
                </NavLink>
              </p>
            </div>
            <div className="col order-3 order-sm-5">
              <NavLink exact to="/rv-parking">
                <img
                  src={require("assets/img/service5.png")}
                  className="circle"
                  alt="RV Parking"
                />
              </NavLink>
              <p>
                <NavLink exact to="/rv-parking" className="nav-link">
                  RV Parking
                </NavLink>
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper shadow-bg">
        <Container>
          <h1 className="head2">CENTRALLY LOCATED PARKING FOR TRUCK DRIVERS</h1>
          <br />
          <p className="text-left">
            When you need to park your truck, why not leave it in a place that
            is near everything else you could want easy access to? Choose
            ParkNation to meet your parking needs and secure a spot on our lot
            close to Highway 73, New Jersey Turnpike, AC Expressway, Highway 295
            and Philadelphia City. Our location makes it easy for you to stay
            close to your truck and still get where you want to be in a timely
            fashion.
          </p>
        </Container>
      </div>
      <div className="panel-wrapper shadow-bg">
        <Container>
          <h1 className="head2">
            SECURE TRUCK PARKING IN SICKLERVILLE, NEW JERSEY
          </h1>
          <br />
          <p className="text-center">
            Our lot boasts a number of security features for your safety and
            protection. These features include:
          </p>
          <ul className="innerList ul-6 p-20 row">
            <li>Expertly-Placed Lighting</li>
            <li>Fencing</li>
            <li>Cameras</li>
            <li>Natural Surroundings</li>
          </ul>
        </Container>
      </div>
      <div className="panel-wrapper">
        <Container>
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("assets/img/about.jpg")}
                alt="Truck Drivers — Truck Parked In A Parking Lot Of A Roadside Restaurant in Plainsboro, NJ"
                title="Truck Drivers — Truck Parked In A Parking Lot Of A Roadside Restaurant in Plainsboro, NJ"
              />
            </div>
            <div className="col-sm-7">
              <h1 className="head2">ABOUT PARK NATION</h1>
              <br />
              <p>
                ParkNation is a new, secure, self-service truck parking lot
                offering all of the features you need for convenient parking.
                When parking with us, drivers feel safe because they know their
                trucks are safe. A central location and customer-first
                operations manager (no middleman) make ParkNation the first
                choice for any truck driver passing through New Jersey.
              </p>
              <br />
              <p>
                To reserve your parking space, give us a call / text at{" "}
                <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133">
                  <b style={{ textDecorationLine: "underline" }}>
                    (856) 210-2133
                  </b>
                </a>{" "}
                today.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomePage;
