import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// import 'typeface-roboto-condensed';
import IndexNavbar from "./components/Navbars/IndexNavbar.js";
import Footer from "./components/Footers/Footer.js";
import FixedIcons from "./components/FixedIcons/FixedIcons.js";
import ScrollToTop from "./components/ScrollToTop.js";
import HomePage from "views/Home.js";
import SemiTruckLot from "views/SemiTruckLot.js";
import ParkingForFleets from "views/ParkingForFleets.js";
import RVParking from "views/RVParking.js";
import ParkYourEquipment from "views/ParkYourEquipment.js";
import Dealerships from "views/Dealerships.js";
import ContactUs from "views/ContactUs.js";
import PrivacyPolicy from "views/PrivacyPolicy.js";
import TermsOfUse from "views/TermsOfUse.js";
import EaglePayPrivacyPolicy from "views/EaglePayPrivacyPolicy.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
      <BrowserRouter>
        <>
        <ScrollToTop />
        {/**/}<IndexNavbar />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <HomePage {...props} />}
          />
          <Route
            exact
            path="/semi-truck-lot"
            render={(props) => <SemiTruckLot {...props} />}
          />
          <Route
            exact
            path="/parking-for-fleets"
            render={(props) => <ParkingForFleets {...props} />}
          />
          <Route
            exact
            path="/rv-parking"
            render={(props) => <RVParking {...props} />}
          />
          <Route
            exact
            path="/park-your-equipment"
            render={(props) => <ParkYourEquipment {...props} />}
          />
          <Route
            exact
            path="/dealerships"
            render={(props) => <Dealerships {...props} />}
          />
          <Route
            exact
            path="/contact-us"
            render={(props) => <ContactUs {...props} />}
          />
	   <Route
            exact
            path="/privacy-policy"
            render={(props) => <PrivacyPolicy {...props} />}
          />
	 <Route
            exact
            path="/terms"
            render={(props) => <TermsOfUse {...props} />}
          />

	  <Route
            exact
            path="/eagle-pay/privacy-policy"
            render={(props) => <EaglePayPrivacyPolicy {...props} />}
          />

          <Redirect to="/" />
        </Switch>
        <Footer />
        <FixedIcons />
        </>
      </BrowserRouter>
      </div>
    );
  }
}

export default App;
