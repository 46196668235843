import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function Dealerships() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center"><h1 className="head1">AUTO DEALERSHIP PARKING LOT</h1></div>
          <p><b>ParkNation</b> offers <b>parking space for car dealerships</b> in <b>Sicklerville, New Jersey</b> and surrounding areas. Do you need a secure place to keep your vehicles? Count on us for convenient parking at fair monthly prices. Not only are we in a great location, but our facility has key security features that makes parking with us ideal.</p>
          <p>Call us today at <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a> to reserve space for your vehicles.</p>
        </Container>
      </div>
      <div className="panel-wrapper cl-bg">
        <Container>
          <h1 className="head2" style={{color: '#fff'}}>WE HAVE PLENTY OF SPACE</h1><br/>
          <p style={{color:'#fff'}}>If you have cars that you need parking spots for, be sure to check with us first. Reserve a half acre or as much space as you need! Finding parking for your vehicles has never been this easy!</p>
        </Container>
      </div>
      <div className="panel-wrapper">
        <Container>
          <h1 className="head2">SECURE DEALERSHIP PARKING LOT IN <br/> SICKLERVILLE, NEW JERSEY</h1><br/>
          <p>Park with us to keep your dealership's vehicles safe and sound. Our facility is equipped to ensure that your vehicles will be secure as long as they are parked at our location. No unauthorized personnel can access the parking lot.</p>
          <p>Security features in place include:</p>
          <br/>
          <ul className="innerList pl-20 ul-4">
            <li>High-Tech Security Cameras</li>
            <li>Effective Lighting</li>
            <li>Fencing</li>
          </ul>
        </Container>
      </div>
      <div className="panel-wrapper pt-0">
        <Container className="brd-top" style={{paddingTop: '40px'}}>
          <div className="row">
            <div className="col-sm-6">
              <h1 className="head2" style={{textAlign: 'left'}}>CALL TODAY TO RESERVE YOUR SPACE</h1><br/>
              <p>If your dealership needs plenty of parking, be sure to call today and ensure that we have a place for you. Pick up the phone now and call / text us at <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a>. A friendly staff member will get you all set up with the parking spaces you need for your dealership's inventory.</p>
            </div>
            <div className="col-md-6">
              <img src={require("assets/img/dealerships.webp")} alt="Dealership Parking — White Truck in Sicklerville, NJ" title="Dealership Parking — White Truck in Sicklerville, NJ" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Dealerships;
