import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function ParkYourEquipment() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center"><h1 className="head1">HEAVY EQUIPMENT PARKING SPACES</h1></div>
          <p>If you need a safe and secure place to leave your heavy equipment, <b>ParkNation</b> in here for you. We provide convenient <b>heavy equipment parking</b> in <b>Sicklerville, New Jersey</b> to give all types of businesses the extra space to keep machinery and other items.</p>
        </Container>
      </div>
      <div className="panel-wrapper cl-bg">
        <Container>
          <h1 className="head2" style={{color: '#fff'}}>WHAT KIND OF EQUIPMENT CAN I PARK?</h1><br/>
          <p className="text-center" style={{color:'#fff'}}>Our equipment parking spaces can be used to hold a large variety of equipment including:</p>
          <br/>
          <div className="row">
            <div className="col-md-4">
              <ul className="innerList">
                <li>Generators</li>
                <li>Containers</li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="innerList">
                <li>Landscaping Equipment and Supplies</li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="innerList">
                <li>HVAC Equipment</li>
                <li>Any Heavy Equipment</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper">
        <Container>
          <h1 className="head2">ADVANTAGES OF PARKING WITH PARKNATION</h1><br/>
          <p>You need to leave your equipment in a place that you can trust. That's what makes Park Nation the lot to count on. Our parking lot offers key features that make parking important equipment a safe, no-hassle experience.</p>
          <br/>
          <ul className="innerList row pl-0 ul-6-m-0">
            <li>Secure Fencing</li>
            <li>Located Near Major Highways</li>
            <li>Quality Lighting</li>
            <li>Monthly Rates</li>
            <li>Security Cameras</li>
            <li>Service From a Dependable Operations Manager</li>
          </ul>
        </Container>
      </div>
      <div className="panel-wrapper pt-0">
        <Container className="brd-top" style={{paddingTop: '40px'}}>
          <div className="row">
            <div className="col-sm-6">
              <h1 className="head2" style={{textAlign: 'left'}}>WE'LL KEEP YOUR EQUIPMENT SAFE</h1><br/>
              <p>At Park Nation, we are committed to providing every customer with a great experience. We have taken measures to ensure that when you leave your equipment parked with us, it will be in safe and secure hands. Our security features and commitment to excellence make us the best equipment parking location in New Jersey.</p>
              <br/>
              <p>Let us show you. Call <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a> to reserve your parking spot.</p>
            </div>
            <div className="col-md-6">
              <img src={require("assets/img/park-your-equipment.jpg")} alt="Equipment Parking — Two Middle Class Semi Trucks With Box Trailers in Sicklerville, NJ" title="Equipment Parking — Two Middle Class Semi Trucks With Box Trailers in Sicklerville, NJ" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ParkYourEquipment;
