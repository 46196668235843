import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function RVParking() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center"><h1 className="head1">RV PARKING in Sicklerville, NJ</h1></div>
        </Container>
      </div>
      <div className="panel-wrapper rv-parking-bg"
      style={{
        background: "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(" + require("assets/img/rv-parking-bg.jpeg") + ")",
      }}>
      <Container>
        <div className="row">
          <div className="col-sm-6">
            <h1 className="head2 text-left">Safe and Secure RV Parking in Sicklerville, NJ and Philadelphia area.</h1><br/>
            <p>Your safety and security is our #1 priority. make us your RV parking facility of choice and take advantage of our prime location and customer-first attitude.</p>
          </div>
          <div className="offset-md-1 col-md-5">
            <img src={require("assets/img/rv-parking.webp")} alt="Sicklerville, NJ" title="Sicklerville, NJ" />
          </div>
        </div>
      </Container>
      </div>
    </div>
  );
}

export default RVParking;
