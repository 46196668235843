import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function SemiTruckLot() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center">
            <h1 className="head1">SEMI-TRUCK PARKING LOT</h1>
            <p className="text-left"><b>ParkNation</b> is a brand-new premium <b>semi-truck parking</b> facility offering conveniently-located parking on a secure lot. Our parking lot offers all of the amenities truck drivers need, and it is centrally located to nearby points of interest. If you are driving through an area near <b>Sicklervile, New Jersey,</b> make us your semi-truck parking facility of choice and take advantage of our prime location and customer-first attitude. We are conveniently located to <b>Philadelphia</b> and <b>Camden</b> ports and as well as shipping hubs in <b>Vineland</b> and <b>Swedesboro, New Jersey.</b> </p>
            <p className="text-left">Call / Text <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a> to reserve your space.</p>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper cl-bg services">
        <Container>
          <h1 className="head2" style={{color: '#fff', fontSize: '29px'}}>THE BEST PLACE TO PARK YOUR SEMI-TRUCK IN SICKLERVILLE, NEW JERSEY</h1><br/>
          <p style={{color:'#fff'}}>Semi-truck drivers need a few important features from their parking location and Park Nation fits the bill. We offer:</p>
          <br/>
          <ul className="innerList ul-6 row">
            <li>A Location Central to Major New Jersey Highways</li>
            <li>Easy Parking with No Middleman and No Hassles</li>
            <li>Fencing, Lighting, and Cameras for Complete Security</li>
          </ul>
        </Container>
      </div>
      <div className="panel-wrapper">
        <Container>
          <div className="row">
            <div className="col-sm-6">
              <h1 className="head2">PARK YOUR SEMI-TRUCK 24-HOURS PER DAY</h1><br/>
              <p>Our semi-truck parking space is accessible at any time of the day. No matter where you are on your route, no matter the hour, you can count on Park Nation to have your space ready for you.</p>
              <br/>
              <p>While access to the lot is always available, security is top-notch and no unauthorized personnel can gain access. Rest easy knowing that you can park with us any time and your truck will be safe.</p>
            </div>
            <div className="col-md-6">
              <img src={require("assets/img/semi-truck-lot.jpg")} alt="Semi-Truck Parking — Trucks Parking in Sicklerville, NJ" title="Semi-Truck Parking — Trucks Parking in Sicklerville, NJ" />
            </div>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper pt-0">
        <Container className="brd-top" style={{paddingTop: '40px'}}>
          <h1 className="head2">LOCATED NEAR SHOPPING AND OTHER AREAS</h1><br/>
          <p className="text-left">Because Park Nation is in a central location, you have easy access to the highways as well as shopping areas.</p>
          <p className="text-left">Call / Text <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a> to take advantage of our convenient parking.</p>
        </Container>
      </div>
    </div>
  );
}

export default SemiTruckLot;
