import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function ParkingForFleets() {
  return (
    <div className="nav-height">
      <div className="panel-wrapper">
        <Container>
          <div className="text-center">
            <h1 className="head1">VEHICLE FLEET PARKING</h1>
            <p className="text-left">Choose <b>ParkNation</b> for <b>convenient parking</b> of your entire fleet of vehicles. Offering secure space at a convenient central location in S<b>icklerville, New Jersey,</b> Park Nation is the best choice when you need a parking lot to hold your fleet. We provide assigned parking and secured facilities that is monitored by security professional and surveillance. </p><br/>
            <p className="text-left">For additional information about our services, please Call / Text us at <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133.</b></a></p>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper cl-bg services">
        <Container>
          <h1 className="head2" style={{color: '#fff'}}>PARKING FOR A VARIETY OF FLEETS INCLUDING:</h1><br/>
          <ul className="innerList ul-4 row">
            <li>School Buses</li>
            <li>Tankers</li>
            <li>Boats</li>
            <li>First Responder Vehicles</li>
            <li>Limousines</li>
            <li>RVs</li>
          </ul>
        </Container>
      </div>
      <div className="panel-wrapper">
        <Container>
          <div className="row">
            <div className="col-sm-6">
              <h1 className="head2">LIGHT VEHICLE MAINTENANCE AVAILABLE</h1><br/>
              <p>Do some of your vehicles need service or maintenance? Our fleet parking area offers service for minor repairs. Get convenient parking and the vehicle maintenance you need at the same time.</p>
            </div>
            <div className="col-md-6">
              <img src={require("assets/img/parking-for-fleets.webp")} alt="Fleet Parking — Truck at Sicklerville, NJ" title="Fleet Parking — Truck at Sicklerville, NJ" />
            </div>
          </div>
        </Container>
      </div>
      <div className="panel-wrapper pt-0">
        <Container className="brd-top" style={{paddingTop: '40px'}}>
          <h1 className="head2">DISCOUNTED RATES AVAILABLE</h1><br/>
          <p className="text-left">For larger fleets, space is available at a discount! Call / Text us today at <a href="tel:(856) 210-2133" runtime_url="tel:(856) 210-2133"><b style={{textDecorationLine: 'underline'}}>(856) 210-2133</b></a> to discuss pricing.</p>
        </Container>
      </div>
    </div>
  );
}

export default ParkingForFleets;
